import * as React from "react";
const SVGCoponents =(props) => (
    <svg width="200" height="100" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
  <rect width="100%" height="100%" fill="#f8f9fa"/>
  <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="28" font-weight="bold" fill="#2d3748" text-anchor="middle" alignment-baseline="middle">
    Vote <tspan fill="#2563eb">Pro</tspan>
  </text>
  <circle cx="170" cy="50" r="10" fill="#2563eb"/>
  <polyline points="165,50 170,55 175,45" stroke="white" stroke-width="2" fill="none"/>
</svg>
);
export default SVGCoponents; 