import './App.scss';
import Navbar from './component/navbar';
import HeroSection,{ SecondSection,Services,Experts,AffordablePlans,CampaignManagement,ContactInfo} from './component/herosection';
import { Footer } from './component/footer';
function App() {
  return (
  <>
  <div>
      <Navbar />
      <HeroSection />
      <SecondSection />
      <Services />
      <Experts />
      <AffordablePlans />
      <CampaignManagement />
      <ContactInfo />
      <Footer />
    

    </div>
  </>
  );
}

export default App;
