import React, { useState } from "react";
import SVGCoponents from "./svgComponent";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false); // Close the menu when a link is clicked
  };

  return (
    <div className="navbar">
      <div className="container">
        {/* Logo */}
        <div className="navbar__logo">
          <div className="flex flex-shrink-0 items-center">
            <SVGCoponents className="h-5 w-auto" />
          </div>
          <span className="navbar__logo-text"></span>
        </div>

        {/* Navbar Links */}
        <div className={`navbar__links ${isMenuOpen ? "active" : ""}`}>
          <a href="#Home" className="navbar__link" onClick={handleLinkClick}>Home</a>
          <a href="#About" className="navbar__link" onClick={handleLinkClick}>About</a>
          <a href="#Services" className="navbar__link" onClick={handleLinkClick}>Services</a>
          <a href="#Team" className="navbar__link" onClick={handleLinkClick}>Team</a>
          <a href="#Price" className="navbar__link" onClick={handleLinkClick}>Pricing</a>
          <a href="#work" className="navbar__link" onClick={handleLinkClick}>How it Works</a>
          <a href="#info" className="navbar__link" onClick={handleLinkClick}>Contact Info</a>
        </div>

        {/* Hamburger Menu */}
        <div className={`navbar__hamburger ${isMenuOpen ? "active" : ""}`} onClick={handleMenuToggle}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;