import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css"; // If using NPM import

const HeroSection = () => 
  {
  return(
    <div className="hero-container" id="Home">
      {/* First Section - Background Image */}
      <section className="hero-section">
        <img
          alt="Background image showing various campaign elements"
          className="hero-section__image"
          src="https://storage.googleapis.com/a1aa/image/fV12lHPXAnwQddx4Vmr2Q0aUbgOySQ_Be1L3cYBLW_A.jpg"
        />
        <div className="hero-section__overlay">
          <h1 className="hero-section__title">Voter Pro</h1>
          <p className="hero-section__subtitle">
            Powering Campaigns with Smart Tools
          </p>
          <a
            className="hero-section__cta"
            href="#work" // Navigate to the second section
          >
            View Services
          </a>
        </div>
      </section>
    </div>
  );
};

export const SecondSection = () => {
  return (
    <div className="container" id="About">
      <div className="content">
        <div className="text-section">
          <h2 className="sub-title">EMPOWERING CAMPAIGNS</h2>
          <h1 className="main-title">Streamline your outreach effectively</h1>
          <p className="description">
            Voter Pro is your ultimate partner in navigating the complexities of
            political campaigns. Based in Delhi, we specialize in campaign
            management, social media management, video creation, and surveys.
            Our platform empowers candidates to streamline their outreach
            efforts, engage meaningfully with voters, and analyze public opinion
            with precision. Experience the difference that efficient campaign
            management can make as you connect with your audience like never
            before.
          </p>
          <a className="contact-link" href="#info">
            Get in touch
          </a>
        </div>
        <div className="image-section">
          <img
            alt="A person with a megaphone in front of a smartphone with various social media icons and flags in the background"
            src="https://storage.googleapis.com/a1aa/image/1bWez9x9mhvZmkLBh3OFEtRLttuiXM7anSg73CMpuwI.jpg"
            className="image"
          />
        </div>
      </div>
    </div>
  );
};

export const Services = () => {
  return (
    <div className="services-container" id="Services">
      <h1 className="services-title">
        Engage voters and analyze opinions effectively.
      </h1>
      <div className="services-grid">
        <div className="service-card">
          <img
            alt="A crowd of people holding signs and flags at a political rally"
            className="service-image"
            src="https://storage.googleapis.com/a1aa/image/JuCJ94q-vdBgFp0ADaCyIsVz9koyBkR-lKdatg44Arw.jpg"
          />
          <div className="service-content">
            <a href=""><h2 className="service-title">
              Campaign management
              <i className="fas fa-chevron-right"></i>
            </h2></a>
            <p className="service-description">
              Effortlessly manage your political campaigns with our innovative
              platform.
            </p>
          </div>
        </div>

        <div className="service-card">
          <img
            alt="A person using a laptop with social media icons on the screen"
            className="service-image"
            src="https://storage.googleapis.com/a1aa/image/c77av81OfSqaW0ExOQjpKNB-GbaGggV4v1H95oOmgsk.jpg"
          />
          <div className="service-content">
            <h2 className="service-title">
              Social media management
              <i className="fas fa-chevron-right"></i>
            </h2>
            <p className="service-description">
              Boost your online presence and engage voters through social media
              effectively.
            </p>
          </div>
        </div>

        <div className="service-card">
          <img
            alt="A person recording videos with a camera setup"
            className="service-image"
            src="https://storage.googleapis.com/a1aa/image/L07zwK2pGBGiYhyPihUwUK8LlNguNOqICmM5DDxDYnk.jpg"
          />
          <div className="service-content">
            <h2 className="service-title">
              Video creation
              <i className="fas fa-chevron-right"></i>
            </h2>
            <p className="service-description">
              Create impactful videos to communicate your campaign message
              effectively.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Experts = () => {
  return (
    <div className="experts-container" id="Team">
      <h2 className="experts-subtitle">OUR EXPERTS</h2>
      <h1 className="experts-title">Dedicated to your campaign success</h1>
      <div className="experts-grid">
        <div className="expert-card">
          <h3 className="expert-name">Adv. Gaurav Adhana</h3>
          <p className="expert-role">Campaign Strategist</p>
          <p className="expert-description">
            Gaurav Adhana is an experienced campaign strategist focused on
            innovative outreach. He believes in empowering communities through
            informed decision-making.
          </p>
          <button className="read-more-btn">READ MORE</button>
        </div>
        <div className="expert-card">
          <h3 className="expert-name">Hari Om</h3>
          <p className="expert-role">Social Media Manager</p>
          <p className="expert-description">
            Hari Om is a skilled social media manager with a focus on
            storytelling. He excels in creating content that builds community
            and drives engagement.
          </p>
          <button className="read-more-btn">READ MORE</button>
        </div>
        <div className="expert-card">
          <h3 className="expert-name">Prakash Suryavanshi</h3>
          <p className="expert-role">Developer Team Lead</p>
          <p className="expert-description">
            Prakash is a committed data analyst who provides actionable insights
            for campaigns. he believes in informed decision-making for better
            outcomes.
          </p>
          <button className="read-more-btn">READ MORE</button>
        </div>
      </div>
    </div>
  );
};

export const AffordablePlans = () => {
  return (
    <div className="affordable-plans-container" id="Price">
      <h2 className="section-subtitle">AFFORDABLE PLANS</h2>
      <h1 className="section-title">Flexible options for every campaign</h1>
      <div className="plans-grid">
        <div className="plan-card">
          <img
            alt="Campaign management tool image"
            className="plan-icon"
            src="https://storage.googleapis.com/a1aa/image/XqgNQpCtzy7C_N_rpCKhX3IVkY7aExaNgF0Y_qV4838.jpg"
          />
          <h3 className="plan-title">Campaign management tool</h3>
          <p className="plan-description">
            Elevate your campaign strategy with our all-in-one management tool.
          </p>
          <p className="plan-price">Rs. 20000/Month</p>
          <p className="plan-details">
            Voter Pro’s Campaign Management Tool empowers political candidates
            to organize and execute their campaigns effectively.
          </p>
        </div>
        <div className="plan-card">
          <img
            alt="Social media management suite image"
            className="plan-icon"
            src="https://storage.googleapis.com/a1aa/image/8JYM17np1WGI6t373-xYX-RrZMlJjTfLZNYC9zRexgU.jpg"
          />
          <h3 className="plan-title">Social media management suite</h3>
          <p className="plan-description">
            Transform your digital presence with powerful social media tools.
          </p>
          <p className="plan-price">Rs. 5000/month</p>
          <p className="plan-details">
            Engage voters like never before with Voter Pro’s Social Media
            Management Suite. This tool allows you to create, schedule, and
            analyze your social media campaigns.
          </p>
        </div>
        <div className="plan-card">
          <img
            alt="Video creation and editing tool image"
            className="plan-icon"
            src="https://storage.googleapis.com/a1aa/image/sdwDBzehKYDiswOBX8jE7nnI-JuLRMK93y8WR8N5kQQ.jpg"
          />
          <h3 className="plan-title">Video creation and editing tool</h3>
          <p className="plan-description">
            Craft captivating campaign videos effortlessly with our editing
            tool.
          </p>
          <p className="plan-price">Rs. 10000/month</p>
          <p className="plan-details">
            Bring your campaign messages to life with Voter Pro’s Video Creation
            and Editing Tool. This user-friendly tool helps you create
            professional-quality videos with ease.
          </p>
        </div>
      </div>
    </div>
  );
};

export const CampaignManagement = () => {
  return (
    <div className="campaign-management-container" id="work">
      <h2 className="section-subtitle">STREAMLINE YOUR CAMPAIGN</h2>
      <h1 className="section-title">
        Engage voters and analyze opinions effectively.
      </h1>
      <div className="campaign-grid">
        <div className="campaign-card">
          <img
            alt="Campaign management tools including notebooks, folders, and a laptop"
            className="campaign-image"
            src="https://storage.googleapis.com/a1aa/image/Dul3UEXbp8P6L5ZI0VOSQPbUcS_wHZx6nqP9LLeTmDE.jpg"
          />
          <h3 style={{marginLeft:"20px"}} className="card-title">Campaign management</h3>
          <p className="card-description">
            Streamline your campaign operations with powerful management tools.
          </p>
          <a className="learn-more-link" href="#">
            Learn more
          </a>
        </div>
        <div className="campaign-card">
          <img
            alt="Social media management tools displayed on a smartphone and various icons"
            className="campaign-image"
            src="https://storage.googleapis.com/a1aa/image/uAEB1Mad97rNXSeT5EPdA6qZwN45EqgKT_Xl_EM29Tk.jpg"
          />
          <h3 className="card-title">Social media management</h3>
          <p className="card-description">
            Amplify your campaign's voice through effective social media
            management.
          </p>
          <a className="learn-more-link" href="#">
            Learn more
          </a>
        </div>
      </div>
    </div>
  );
};

export const ContactInfo = () => {
  return (
    <div className="contact-info-container" id="info">
      <div className="background-image">
        <div className="contact-form">
          <h2 className="section-title">Get in touch</h2>

          <div className="contact-item">
            <i className="fas fa-phone-alt mr-2"></i>
            <span>+91 85955-55349</span>
          </div>

          <div className="contact-item">
            <i className="fas fa-envelope mr-2"></i>
            <a className="email-link" href="mailto:myvoteonline01@gmail.com">
              myvoteonline01@gmail.com
            </a>
          </div>

          <h2 className="section-title">Location</h2>
          <div className="contact-item">
            <i className="fas fa-map-marker-alt mr-2"></i>
            <span>
              A-743, Sarita Vihar
              <br />
              Delhi, DL 110076 IN
            </span>
          </div>

          <h2 className="section-title">Follow Us</h2>
          <div className="contact-item social-icons">
            <a
              href="https://www.instagram.com/myvoteonline01?igsh=MXQ4cnEzeDdpaXBtMg%3D%3D&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram text-pink-600 text-xl"></i>
            </a>
            <a
              href="https://www.facebook.com/share/15mA9nafz4/?mibextid=wwXIfr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook text-blue-600 text-xl"></i>
            </a>
            <a
              href="https://youtube.com/@myvoteonline?si=rWKXOIFIF_3qetgY"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube text-red-600 text-xl"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};


export default HeroSection;
